/* You can add global styles to this file, and also import other style files */

@import "styles/fonts";
@import "styles/material-theme";

@import "styles/general";
@import "styles/theme/default";
@import "styles/modify-material";
@import "styles/components";
@import "styles/pages";
