html {
  font-size: 16px;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  line-height: normal;
  font-family: 'Roboto', sans-serif !important;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* > * {
  box-sizing: inherit;
}

body {
  font-size: 0.875rem;
  box-sizing: border-box;
  color: var(--tes-text-main);

  /* total width */
  ::-webkit-scrollbar {
    background-color: var(--tes-main-input-field);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1rem;
    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: var(--tes-main-input-field);
    border-radius: 1rem;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: var(--tes-main-inactive);
    border-radius: 1rem;
    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

button {
  font-family: inherit;
  font-size: inherit;
}

input {
  font-family: inherit;
  font-size: inherit;
}
