.snack-bar, .snack-bar.mat-mdc-snack-bar-container {
  white-space: pre-line;

  .mdc-snackbar__surface {
    box-shadow: -1px 3px 20px 1px #212C3726;
    border-radius: 0.5rem;
  }

  &._success {
    .mdc-snackbar__surface {
      background-color: var(--tes-success-50);
      border: 1px solid var(--tes-success-100);
    }

    .mdc-snackbar__label {
      color: var(--tes-success-300);
    }
  }

  &._danger {
    .mdc-snackbar__surface {
      background-color: var(--tes-danger-50);
      border: 1px solid var(--tes-danger-100);
    }

    .mdc-snackbar__label {
      color: var(--tes-danger-300);
    }
  }

  &._warning {
    .mdc-snackbar__surface {
      background-color: var(--tes-warning-50);
      border: 1px solid var(--tes-warning-100);
    }

    .mdc-snackbar__label {
      color: var(--tes-warning-600);
    }
  }

  &._info {
    .mdc-snackbar__surface {
      background-color: var(--tes-main-widget-bg);
      border: 1px solid var(--tes-main-dividers);
    }

    .mdc-snackbar__label {
      color: var(--tes-primary);
    }
  }
}
