/* main */
$tes-primary: #343E48;
$tes-secondary: #72879E;
$tes-secondary-2: #BFCFE0;
$tes-main-inactive: #CAD2DA;
$tes-main-input-field: #F2F4F7;
$tes-main-dividers: #E0E0E0;
$tes-main-white: #FFFFFF;

/** Services */
$tes-service-blue: #EEF9FF;
$tes-service-green: #EAF4E6;
$tes-service-pink: #F3EBFB;

$tes-main-sidebar: #343E48;
$tes-main-sidebar-chosen-point: #48515A;
$tes-main-icons: #72879E;
$tes-main-widget-bg: #FEFEFE;
$tes-main-web-background: #F2F4F7;

$tes-success: #1CD29C;
$tes-warning: #EFCE5A;
$tes-danger: #FC7D7D;

/* text */
$tes-text-grey: #9E9E9E;
$tes-text-black: #232323;
$tes-text-main: #343E48;

/* accordion colors */
$tes-accordion-colors-pink-light: #FAF5FF;
$tes-accordion-colors-pink-dark: #A891BF;
$tes-accordion-colors-blue-light: #EEF9FF;
$tes-accordion-colors-blue-dark: #213A8B;
$tes-accordion-colors-green-light: #F1FFFC;
$tes-accordion-colors-green-dark: #16ADBE;
$tes-accordion-colors-grey-light: #F2F4F7;
$tes-accordion-colors-grey-dark: #323232;
$tes-accordion-colors-yellow-light: #FCF7EA;
$tes-accordion-colors-yellow-dark: #CA9715;

/* gradient */
$tes-gradient-main: linear-gradient(180.02deg, #39434D 5.74%, #0F1923 99.98%);
$tes-gradient-radial: radial-gradient(106.64% 106.64% at 50% 50%, #72879E 0%, #162330 63.54%);
$tes-gradient-sliders: linear-gradient(173.16deg, #FFFFFF -46.88%, #E8E8E8 94.64%);

$tes-gradient-bg-blue: linear-gradient(169.27deg, #FFFFFF 5.77%, #E5F6FF 95.15%);
$tes-gradient-bg-green: linear-gradient(170.53deg, #FFFFFF 5.39%, #F2FFED 95.28%);
$tes-gradient-bg-pink: linear-gradient(170.53deg, #FFFFFF 5.39%, #F2FFED 95.28%);

$tes-gradient-monitoring-1: linear-gradient(250.43deg, #C2D7EC 19.08%, #92ADC9 82.61%);
$tes-gradient-monitoring-2: linear-gradient(180deg, #D8E1EA 6.37%, #ECF2F8 100%);
$tes-gradient-monitoring-3: linear-gradient(180deg, #72879E 0%, #A7B9CB 100%);
$tes-gradient-monitoring-4: linear-gradient(180deg, #D8E1EA 0%, rgba(216, 225, 234, 0.15) 100%);

$tes-gradient-green: linear-gradient(180deg, #AAFFF0 0%, #00A1B7 100%);
$tes-gradient-orange: linear-gradient(184.71deg, #FF8580 -51.35%, #FFAE5E 96.2%);
$tes-gradient-grey: linear-gradient(173.16deg, #96B1CC -46.88%, #C3D8ED 94.64%);
