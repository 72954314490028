.page-login {
  .mat-mdc-floating-label {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 2.25rem;
    top: 2rem;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
    .mat-mdc-form-field-infix {
      padding-top: 2rem;
    }

    .mat-mdc-floating-label {
      color: inherit;
      top: 2rem;
    }
  }

  .mat-form-field-appearance-fill .mdc-line-ripple::before {
    background-color: #e0e0e0;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #ffffff00!important;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none!important;
  }
}
